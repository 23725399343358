<template>
  <div>
    <Menu/>
    <div id="news">
      		<div class="newsPage">
			<img src="../assets/images/newsBanner.png" class="banner w100" />
			<div class="content bg_fff nContent">
				<!-- crumbs -->
				<div class="crumbs">
					<div class="content d-flex d-flex-middle">
						<p class="color9 fontSize12">当前所在位置：</p>
						<router-link to="/" class="color9 fontSize12">首页</router-link>
						<p class="color9 fontSize12 and">></p>
						<p class="color fontSize12">新闻资讯</p>
					</div>
				</div>
				<!-- tabs -->
				<div class="tabs d-flex d-flex-middle d-flex-center">
					<p :class="{active:newsTypeActive==0}" @click="changeNewsType(0)">行业新闻</p>
					<p :class="{active:newsTypeActive==1}" @click="changeNewsType(1)">学校新闻</p>
				</div>
				<!-- list -->
				<div class="list d-flex d-flex-middle d-flex-between d-flex-wrap">
					<router-link :to="'/NewsDetail/' + item.news_Id" class="li d-flex d-flex-middle" data-id="20" v-for="(item,index) in newList" :key="index">
						<div class="li_img diyImg"  :style="{'background-image': 'url('+$store.state.httpUrl + item.news_ImgPath+')'}"></div>
						<div class="liInfo">
							<p class="color3 fontSize16 txtOver">{{item.news_Title}}</p>
							<p class="color9 fontSize12">{{item.news_ReleaseDate}}</p>
							<p class="color3 fontSize12 txtOver2">{{item.news_Describe}}</p>
						</div>
					</router-link>
				</div>
			</div>
		</div>

			        <div style="text-align: center; margin-top: 60px;margin-bottom: 60px;">
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="total" :page-size="8">
        </el-pagination>
      </div>
    </div>
    <Foot/>
    <RightGuide/>
    
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Foot from "@/components/Foot";
import RightGuide from "@/components/RightGuide";
import { splitListNews } from "@/api/app"
export default {
  name: "Home",
  components: {
    Menu, Foot, RightGuide
  },
  data() {
    return {
		newsTypeActive:0,
		total:0,
		newList:[]
    };
  },
  mounted() {
	  this.getSplitListNews()
  },

  methods: {
    getSplitListNews(page) {
      const params = {
        news_Type: this.newsTypeActive,
        page: page || 1
      }
      splitListNews(params).then((res) => {
        if (res.status) {
          const data = res.data
          this.newList = data.listData
          this.total = data.totalCount
        }
      })
    },

	changeNewsType(v){
		this.newsTypeActive = v
		this.getSplitListNews()
	},

	//分页
	handleCurrentChange(page){
		this.getSplitListNews(page)
	}
  },
};
</script>

<style lang="scss">
/*  */
body{
	background-color: #FAFAFA;
}
/*  */

#news {
.newsPage{
	position: relative;
	box-sizing: border-box;
	padding-top: 270px;
	min-height: 800px;
	padding-bottom: 100px;
}
.banner{
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
.nContent{
	position: relative;
	z-index: 22;
	background-color: #fff;
	border-radius: 10px;
	box-sizing: border-box;
	padding: 0 40px;
}
.tabs{
	margin: 20px 0;
}
.tabs p{
	width: 130px;
	height: 50px;
	line-height: 50px;
	cursor: pointer;
	text-align: center;
	background: #F7F7F8;
	margin: 0 5px;
	font-size: 16px;
}
.tabs .active{
	background-color: #0087ED;
	color: #fff;
}
.list{
	padding-bottom: 20px;
	margin-top: 70px;
}
.li{
	width: 570px;
	box-sizing: border-box;
	padding: 15px;
	background-color: #F7F7F8;
	margin-bottom: 30px;
	cursor: pointer;
	position: relative;
	top: 0;
	transition: all 0.5s;
}
.li:hover{
	top: -20px;
	box-shadow: 0 0 5px 2px #ddd;
}
.li .li_img{
	width: 150px;
	height: 150px;
}
.liInfo{
	width: calc(100% - 165px);
	margin-left: 15px;
	height: 150px;
}
.liInfo p:nth-of-type(1){
	font-weight: bold;
	line-height: 44px;
	height: 44px;
	position: relative;
	margin-bottom: 15px;
}
.liInfo p:nth-of-type(1)::after{
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	width: 60px;
	height: 2px;
	background-color: #0087ED;
}
.liInfo p:nth-of-type(3){
	margin-top: 20px;
	line-height: 20px;
	height: 40px;
}
}

</style>